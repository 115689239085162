@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /*height: 100vh;*/
    background-color: black;
}

.required {
    position: relative;
}

.required::after {
    content: '*';
    display: inline-block;
    font-weight: bold;
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}